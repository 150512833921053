<template>
    <div class="cg_main">
        <div style="width: 100%;height: 5px;background: #EFEFEF; "></div>
        <div class="cg_main_son">
            <div style=" display: flow-root;margin-bottom: 25px;margin-top: 25px;">
                <div style="float: left">
                    <el-breadcrumb separator="/" style="padding-top: 13px">
                        <el-breadcrumb-item><router-link :to="{path:'/configurationList'}">我的项目</router-link></el-breadcrumb-item>
                        <el-breadcrumb-item>{{this.$route.query.name}}</el-breadcrumb-item>
                    </el-breadcrumb>

                   <!-- <el-button type="primary" >已完成</el-button>
                    <el-button >未完成</el-button>
                    <el-button >删除</el-button>
                    <el-button type="primary" plain>添加产品</el-button>
                    <el-button type="primary" plain>导入配置</el-button>
                    <el-button type="primary" plain>提交审核</el-button>-->
                </div>
                <div style="float: right">
                    <el-popconfirm  title="是否审核"
                                    @confirm="addCommitAll()" >
                        <el-button :disabled="multiple"  type="primary" style="margin-right: 10px"  slot="reference">审核</el-button>
                    </el-popconfirm>
                    <el-button type="primary" plain @click="openDia">导入配置</el-button>
                    <!--<el-input style="width: 240px;" placeholder="请输入产品信息" v-model="input3" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>-->
                </div>

            </div>
            <div style="margin-bottom: 40px;">
                <el-table
                        v-loading="loading"
                        :data="tableData"
                        style="width: 100%;padding: 30px;" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="50" align="center"  :selectable="selectable"/>
                    <el-table-column
                            prop="name"
                            label="配置单名称"
                             align="center">
                        <template slot-scope="scope">
                            <div style="color:rgb(10, 142, 255);text-decoration:underline;cursor:pointer;" @click="goDetial(scope.row.id,scope.row.name,scope.row.type)">
                                {{scope.row.name }}
                            </div>

                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="state"
                            label="状态"
                            show-overflow-tooltip
                    >
                        <template slot-scope="scope">

                            <span v-if="scope.row.state=='0'">未完成</span>
                            <span v-else-if="scope.row.state=='1'">已完成</span>
                            <span v-else-if="scope.row.state=='2'">审核中</span>
                            <span v-else-if="scope.row.state=='3'">审核通过</span>
                            <span v-else-if="scope.row.state=='4'">审核未通过</span>
                            <span v-else-if="scope.row.state=='5'">申请退回中</span>
                            <span v-else-if="scope.row.state=='6'">退回成功</span>
                            <span v-else-if="scope.row.state=='7'">退回失败</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="approveOpinion"
                            label="说明"
                            show-overflow-tooltip
                    >
                    </el-table-column>
                    <!--<el-table-column-->
                            <!--prop="version"-->
                            <!--label="版本"-->
                            <!--show-overflow-tooltip-->
                    <!--&gt;-->
                    <!--</el-table-column>-->
                    <!-- <el-table-column
                            prop="catalogutPrice"
                            label="清单价(元)"
                            show-overflow-tooltip
                    >
                    </el-table-column> -->
                    <el-table-column
                            prop="sets"
                            label="套数"
                            show-overflow-tooltip
                    >
                        <template slot-scope="scope">
                            <el-input style="width: 80px"   type="number" size="small" v-model="scope.row.sets"   :min="0"></el-input>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                            prop="totalPrice"
                            label="总价"
                            show-overflow-tooltip
                    >
                    </el-table-column> -->
                    <el-table-column
                            prop="listTime" width="160"
                            label="创建日期">
                    </el-table-column>

                    <el-table-column
                            prop="address"
                            label="操作" width="222">
                        <template slot-scope="scope">
                            <el-button @click="saveSet(scope.row.id,scope.row.sets)"  type="text" style="color: #0A8EFF;margin-right: 10px"  slot="reference">保存</el-button>
                            <el-popconfirm  title="是否审核"
                                            @confirm="addCommit(scope.row.id)" >
                                <el-button v-show="scope.row.state=='1'" type="text" style="color: #0A8EFF;margin-right: 10px"  slot="reference">审核</el-button>
                            </el-popconfirm>
                            <el-popover
                                    placement="top"
                                    width="260"
                                    :ref="scope.$index"
                                   >
                                <p>请选择下载格式</p>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="primary" @click="downFile(scope.row.id,scope.row.name,'pdf')">pdf</el-button>
                                    <el-button type="primary" size="mini" @click="downFile(scope.row.id,scope.row.name,'xlsx')">excel</el-button>
                                    <el-button type="primary" size="mini" @click="downFile(scope.row.id,scope.row.name,'csv')">csv</el-button>
                                </div>
                                <el-button   type="text" style="color: #0A8EFF;margin-right: 10px" slot="reference">下载</el-button>
                            </el-popover>

                            <!--<el-popconfirm title="请选择下载格式" confirm-button-text='PDF'-->
                                           <!--cancel-button-text='EXCEL' @confirm="downFile(scope.row.id,scope.row.name,'pdf')" @cancel="downFile(scope.row.id,scope.row.name,'xlsx')">-->
                                <!--<el-button type="text" style="color: #0A8EFF;margin-right: 10px"  slot="reference">下载</el-button>-->
                            <!--</el-popconfirm>-->
                            <el-popconfirm title="确定删除？" @confirm="delList(scope.row.id,scope.$index)">
                                <el-button type="text" style="color: #0A8EFF" slot="reference">删除</el-button>
                            </el-popconfirm>

                        </template>
                    </el-table-column>
                </el-table>
                <div class="tb_foot" >
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="page.pageNum"
                            :page-size="page.pageSize"
                            layout="total, prev, pager, next"
                            :total="page.total"
                    >
                    </el-pagination>
                </div>
            </div >
        </div>

        <el-dialog title="导入配置" :visible.sync="dialogVisible" width="370px">
            <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="baseImgSrc"
                    :headers="token"
                    :on-error="handleError"
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    :on-success="handleSuccess"
                    :file-list="fileList"
                    accept=".xls,.xlsx"
                    :limit="1"
                    :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">自定义</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload" :loading="btnLoading">确定</el-button>
                <div slot="tip" class="el-upload__tip">只能上传excel文件</div>
            </el-upload>
            <!--<el-form  label-width="80px">

                <el-upload
                        class="uploadBox"
                        :action="baseImgSrc"
                        :on-error="handleError"
                        :file-list="fileList"
                        :on-success="handleSuccess"
                        :on-remove="handleRemove"
                        :limit="1"
                >
                    <el-button size="small" icon="el-icon-upload2" round>上传附件</el-button>
                    <span slot="tip" class="el-upload__tip" style="margin-left: 10px">只能上传excel文件</span>
                </el-upload>

            </el-form>-->

            <!--<span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveFin" :loading="btnLoading">确 定</el-button>
            </span>-->
        </el-dialog>

    </div>
</template>

<script>
    import {
        getConfigFinList,
        downFile,
        getConfigFinDel,
        goCommit,
        aloEditSets,
        goAllCommit,
    } from "@/Api/finance/home";
    import { encrypt,decrypt,exportExcle,getToken } from "@/Util/auth";
    export default {
        name: "index",
        data() {
            return {
                single: true,
                multiple: true,
                ids:[],
                token: {
                    'x-auth-token':getToken()
                },
                fileList: [],
                baseImgSrc: this.axiosUrl + '/sjzx/alo/upload',
                btnLoading: false,
                dialogVisible:false,
                loading: false,
                page: {
                    total: 0, // 总页数
                    pageNum: 1, // 当前页数
                    pageSize: 10 // 每页显示多少条
                },
                tableData:[],
            }
        },
        methods: {
            selectable: function(row, index)
            {
                if(row.state != 2 && row.state != 3){
                    return true;
                }
            },
            handleSelectionChange(selection) {

                this.ids = selection.map(item => item.id);
                this.single = selection.length != 1;
                this.multiple = !selection.length;
            },
            handleSuccess(response, file, fileList) {
                let dd= JSON.parse(decrypt(response))
                if (dd.code == 200) {
                    if(dd.data){
                        this.fileList=[]
                        //this.$store.commit('updateConfigurationInfoList', dd.data.data)
                        /*sessionStorage.setItem("configurationInfoList", JSON.stringify(dd.data));*/
                        this.$router.push({name:'configurationInfo',query: {id:dd.data.title}})
                    }else {
                        this.$message.warning(`请选择正确的excel的格式`);
                    }
                    this.btnLoading=false
                }else {
                    alert(dd.msg);
                }

            },
            /*
            handleError(err, file, fileList) {
                alert('文件上传错误，请检查文件类型')
            },*/
            saveSet(id,set){
                let obj = {
                    id: id,
                    sets: set,
                };
                let ss= encrypt(JSON.stringify(obj))
                aloEditSets(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.$message.success(`保存成功`);
                        this.getprojectList()
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            handleError(err, file, fileList) {
                this.$message.warning({
                    message: '文件上传错误，请检查文件类型',
                    type: 'error',
                    duration: 5 * 1000
                })
            },
            handleExceed(files, fileList) {
                this.$message.warning(`每次只能选择一个文件导入，请删除已选择的附件`);
            },
            submitUpload() {

                this.btnLoading=true
                let that=this
                setTimeout(function(){ that.btnLoading=false; }, 5000);

                this.$refs.upload.submit();
            },
            handleRemove(file, fileList) {
                this.fileList = fileList;
            },

            openDia(){
                this.dialogVisible=true

            },
            addCommit(id){
                let obj = {
                    listId: id,
                    projId: this.$route.query.id,
                };
                let ss= encrypt(JSON.stringify(obj))
                goCommit(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.getprojectList()
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            addCommitAll(){
                let obj = {
                    ids: this.ids,
                    projId: this.$route.query.id,
                };
                let ss= encrypt(JSON.stringify(obj))
                goAllCommit(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.getprojectList()
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            delList(id,index){
                let obj = {
                    ids: [id],
                };
                let ss= encrypt(JSON.stringify(obj))
                getConfigFinDel(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.tableData.splice(index, 1)
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            downFile(id,name,type){
                let that =this
                this.loading=true
                let obj = {
                    id: id,
                    format: type,
                };
                downFile(obj).then(res => {
                    setTimeout(function(){
                        that.loading=false;

                        }, 3000);
                    exportExcle(name,type,res)
                })
            },
            goDetial(id,name,type) {
                this.$router.push({name:'configurationInfo',query: {id:id,name:name,detial:'1',type:type}})
            },
            handleCurrentChange(val) {
                this.page.pageNum = val;
                this.getprojectList();
            },
            getprojectList(){
                let obj = {
                    page: this.page.pageNum,
                    size: this.page.pageSize,
                    type:this.$route.query.type,
                    id:this.$route.query.id
                };
                let ss= encrypt(JSON.stringify(obj))
                getConfigFinList(ss).then(res => {

                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.tableData=dd.data.records
                        this.page.total=dd.data.total
                    }else {
                        alert(dd.msg);
                    }

                })

            },
        },
        mounted() {

            this.getprojectList()

        },
    }
</script>

<style lang="less" scoped>
.cg_main{
    background: white;
    height: 100%;
    display: flow-root;
    .cg_main_son{
        width: 1200px;
        margin: 0px auto;
        min-height: 499px;
        /deep/.el-table{
            padding: 0px !important;
        }
        /deep/.el-table th, .el-table tr{
            height: 60px!important;
            background: #EFEFEF!important;
        }
        .tb_foot{
            height: 60px;
            background: #F5F5F5;
            width: 100%;
            line-height: 60px;
            text-align: right;
            position: relative;
            padding-top: 12px;
            .tbf_l{
                float: left;
                font-size: 14px;
                color: #666666;
                padding-left: 30px;
            }
            .tbf_r{
                float: right;
                font-size: 14px;
                color: #333333;
                padding-right: 30px;
                span{
                    color: #E72A32;
                }
            }
        }
    }
}
</style>
